import $ from 'jquery';
import { html } from 'rails-plus';
import url from 'modules/url';

const sound = new Audio(url('/static-assets/notify.wav'));

const flash = neededReportSize => html`
  <div class="flash-item flash-item_alert">
    <div class="flash-item__body flash-item__body_alert">
      <div class="flash-item__close" />
      Вам нужно сдать еще ${neededReportSize} отчетов.
      Перейдите на вкладку «Отчетность» и сделайте это.
    </div>
  </div>
`;

const init = () => {
  // poll();
  // setInterval(() => poll(), 1000 * 60 * 15);
};

const onFetched = (neededReportSize) => {
  if (neededReportSize > 0) {
    $('#flashes').append(flash(neededReportSize));
    sound.play();
  }
}

const poll = () => {
  fetch(url('/api/reports'), { credentials: 'include' })
    .then(response => response.json())
    .then(json => onFetched(json.needed_report_size))
    .catch(error => console.error(error));
}

const start = () => {
  document.addEventListener('DOMContentLoaded', init);
};

export default { init, poll, start };
