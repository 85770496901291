const delegateClickToToggle = (event) => {
  const node = event.target.closest('.radio-button[data-placeholder]');
  if (!node) return;
  toggleToggle(node);
};

const toggleToggle = (node) => {
  const targetNode = document.querySelector(node.getAttribute('data-target'));
  targetNode.placeholder = node.getAttribute('data-placeholder');
};

const start = () => {
  document.addEventListener('click', delegateClickToToggle);
};

export default { start };
