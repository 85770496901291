import $ from 'jquery';

const onKeyDown = (event) => {
  if (!event.shiftKey && event.keyCode === 13) {
    event.preventDefault();
    Rails.fire(event.target.closest('form'), 'submit');
  }
};

const initElement = (node) => {
  $(node).keydown(onKeyDown);
  node.classList.remove('key-submit-form');
  node.classList.add('key-submit-form_initialized');
};

const initAll = () => {
  const nodes = document.querySelectorAll('.key-submit-form');
  Array.from(nodes).forEach(node => initElement(node));
};

const start = () => {
  document.addEventListener('page:load', initAll);
  document.addEventListener('ajax:success', initAll);
};

export default { initAll, initElement, start };
