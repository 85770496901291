import * as Ladda from 'ladda';

const updateSpinnerColor = (button) => {
  button.setAttribute('data-spinner-color', getComputedStyle(button).color);
};

const initNode = (node) => {
  updateSpinnerColor(node);
  Ladda.bind(node);
}

const initFor = (selector) => {
  const nodes = document.querySelectorAll(selector);
  Array.from(nodes).forEach(node => initNode(node));
};

const initAll = () => {
  initFor('.ladda');
  initFor('button[type=submit]:not(.ladda-button_disabled)');
};

const stopAll = () => {
  Ladda.stopAll();
};

const start = () => {
  document.addEventListener('page:load', initAll);
  document.addEventListener('ajax:success', initAll);
  document.addEventListener('ajax:error', initAll);
  document.addEventListener('ajax:success', stopAll);
  document.addEventListener('ajax:error', stopAll);
};

export default { initAll, initFor, stopAll, start };
