const closeBsModals = () => {
  if (!$('.modal.in').length) return;
  $('.modal.in').modal('hide');
}

const closeRemodals = () => {
  if (!$('.remodal.remodal-is-opened').length) return;
  $('.remodal.remodal-is-opened').remodal().close();
}

const append = (selector, content) => {
  $(selector).append(content);
};

const html = (selector, content) => {
  $(selector).html(content);
};

const modal = (selector, content) => {
  const $item = $(selector);
  $('body').append(content);
  $(selector).last().remodal({ hashTracking: false, closeOnOutsideClick: false }).open();
  if ($item.length === 0) return;
  $item.hide();
  setTimeout(() => { $item.remove(); }, 50);
};

const replace = (selector, content) => {
  const $item = $(selector);
  $(content).insertAfter($item);
  $item.hide();
  setTimeout(() => { $item.remove(); }, 50);
};

const visit = (url) => {
  Turbolinks.visit(url);
};

export default { closeBsModals, closeRemodals, append, html, modal, replace, visit };
