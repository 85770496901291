import $ from 'jquery';
import Cookies from 'js-cookie';
import { html } from 'rails-plus';
import url from 'modules/url';

const sound = new Audio(url('/static-assets/notify.wav'));

let cookie;

const flash = () => html`
  <div class="flash-item flash-item_alert">
    <div class="flash-item__body flash-item__body_alert">
      <div class="flash-item__close" />
      New overdue notifications. Please check.
    </div>
  </div>
`;

const init = () => {
  cookie = parseInt(Cookies.get('crm_overdue_notifications_size'));
  if (!cookie) { cookie = 0; }
  poll();
  setInterval(() => poll(), 1000 * 60 * 10);
};

const onFetched = (overdueNotificationsSize) => {
  if (overdueNotificationsSize > cookie) {
    $('#flashes').append(flash());
    sound.play();
  }

  cookie = overdueNotificationsSize;
  Cookies.set('crm_overdue_notifications_size', cookie, { expires: 365 });
}

const poll = () => {
  fetch(url('/api/v0/overdue_notifications_size'), { credentials: 'include' })
    .then(response => response.json())
    .then(json => onFetched(json.overdue_notifications_size))
    .catch(error => console.error(error));
}

const start = () => {
  document.addEventListener('page:load', init);
};

export default { init, poll, start };
