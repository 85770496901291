import html from 'rails-plus/es6/modules/html';
import node from 'modules/nodeFromHtml';

const emptyNode = (state) => (node(html`
  <span class="c_red">${state.text}</span>
`))

const defaultNode = (state, customersSize) => (node(html`
  <span>
    <span style="float: right; margin-left: 10px;">${customersSize}</span>
    <span>${state.text}</span>
  </span>
`))

const get = (state) => {
  const customersSize = state.element.getAttribute('data-customers-size');
  if (customersSize === '0') { return emptyNode(state); }
  return defaultNode(state, customersSize);
};

export default { get };
