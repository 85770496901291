import ClientList from 'modules/select2/templates/ClientList';

const templates = {};

templates['ClientList'] = (state) => {
  if (!state.id) { return state.text; }
  return ClientList.get(state);
};

const get = (templateName) => ( templates[templateName] )

export default { get };
