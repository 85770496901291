import 'rails-plus/es6/libs';
import 'modules/pagy.js.erb';

import {
  BlueimpGallery,
  FlashItems,
  Flips,
  PageEvent,
  WebFontLoader,
} from 'rails-plus';

import $                          from 'jquery';
import Rails                      from 'rails-ujs';
import Turbolinks                 from 'turbolinks';
import { Application }            from 'stimulus';
import { definitionsFromContext } from 'stimulus/webpack-helpers';

import AsyncPartials        from 'modules/AsyncPartials';
import AutosizedTextareas   from 'modules/AutosizedTextareas';
import FontAwesome          from 'modules/FontAwesome';
import KeySubmitForms       from 'modules/KeySubmitForms';
import LaddaButtons         from 'modules/LaddaButtons';
import OverdueNotifications from 'modules/OverdueNotifications';
import Remodals             from 'modules/Remodals';
import ReportNotifications  from 'modules/ReportNotifications';
import ReportPlaceholders   from 'modules/ReportPlaceholders';
import Select2Mimic         from 'modules/Select2Mimic';
import Select2Selects       from 'modules/Select2Selects';
import SmoothScrollLinks    from 'modules/SmoothScrollLinks';
import ujs                  from 'modules/ujs';

window.$ = $;
window.jQuery = $;
window.Rails = Rails;
window.ujs = ujs;

AsyncPartials.start();
AutosizedTextareas.start();
BlueimpGallery.start();
FlashItems.start();
Flips.start();
KeySubmitForms.start();
LaddaButtons.start();
OverdueNotifications.start();
Rails.start();
Remodals.start();
ReportNotifications.start();
ReportPlaceholders.start();
Select2Mimic.start();
Select2Selects.start();
SmoothScrollLinks.start();
Turbolinks.start();

PageEvent.delegate('turbolinks:load');
WebFontLoader.load('Roboto', 'roboto-loaded');

const application = Application.start();
const context = require.context('controllers', true, /\.js$/);
application.load(definitionsFromContext(context));
