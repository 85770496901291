import { Controller } from 'stimulus';

export default class extends Controller {
  connect() {
    this.targetNode = document.querySelector(this.element.getAttribute('href'));
    this.inputNode = this.targetNode.querySelector('input');

    if (this.inputNode.value.length == 0) {
      this.element.classList.remove('d_n');
      this.targetNode.classList.add('d_n');
    }
  }

  show(event) {
    event.preventDefault();
    this.element.classList.add('d_n');
    this.targetNode.classList.remove('d_n');
  }
}
