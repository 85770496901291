import $ from 'jquery';
import 'select2';

function setGetParameter(paramName, paramValue) {
  const hash = location.hash;
  let url = window.location.href.replace(hash, '');

  if (url.indexOf(paramName + "=") >= 0) {
    var prefix = url.substring(0, url.indexOf(paramName));
    var suffix = url.substring(url.indexOf(paramName));
    suffix = suffix.substring(suffix.indexOf("=") + 1);
    suffix = (suffix.indexOf("&") >= 0) ? suffix.substring(suffix.indexOf("&")) : "";
    url = prefix + paramName + "=" + paramValue + suffix;
  } else {
    if (url.indexOf("?") < 0)
      url += "?" + paramName + "=" + paramValue;
    else
      url += "&" + paramName + "=" + paramValue;
  }

  window.location.href = url + hash;
};

const initNode = (node) => {
  const prefix = $('meta[name="appRoot"]')[0].content;

  $(node).select2({
    ajax: { url: prefix + '/api/v0/mimic_users' },
    minimumInputLength: 3,
  });

  $(node).on('select2:select', (e) => {
    setGetParameter('mimic_user_id', e.params.data.id);
  });
};

const initAll = () => {
  const nodes = document.querySelectorAll('.mimic-user-select:not(.select2-hidden-accessible)');
  Array.from(nodes).forEach(node => initNode(node));
};

const start = () => {
  document.addEventListener('page:load', initAll);
  document.addEventListener('ajax:success', initAll);
};

export default { initAll, start };
